import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dimSortings, filters } from 'AppModules/Ganymede/utils/constants';
import ganymedeActions from 'AppModules/Ganymede/Actions/ganymedeActions';
import { productsMap } from 'AppModules/Ganymede/serverless-service/functions/ganymedeAPI/utils/constants';
import { sortPerList } from 'AppModules/Ganymede/utils/util';
import PricesFilters from './PricesFilters';

const PricesFiltersContainer = () => {
  const dispatch = useDispatch();
  const filtersSelection = useSelector((store) => store.ganymedeReducer.prices.filtersSelection);

  const handleUpdateFilter = (filterName, filterValues) => {
    dispatch(ganymedeActions.updateFilter('prices', filterName, filterValues));
  };
  const handleAddDateFilter = () => {
    dispatch(ganymedeActions.addDateFilter('prices'));
  };
  const handleRemoveDateFilter = (additionalDateFilterID) => {
    dispatch(ganymedeActions.removeDateFilter('prices', additionalDateFilterID));
  };
  const handleChangeDateFilterType = (additionalDateFilterID, value) => {
    dispatch(ganymedeActions.changeDateFilterType('prices', additionalDateFilterID, value));
  };

  // reduce list of products to the ones relevant to the selected parks
  let relevantProducts = [];
  for (const park of filtersSelection.Park) {
    const thisParksProducts = Object.keys(productsMap[park]);
    relevantProducts = [...relevantProducts, ...thisParksProducts];
  }
  relevantProducts = [...new Set(relevantProducts)];
  relevantProducts = sortPerList(relevantProducts, dimSortings.Products);

  return (
    <PricesFilters
      filters={filters}
      relevantProducts={relevantProducts}
      filtersSelection={filtersSelection}
      handleUpdateFilter={handleUpdateFilter}
      handleAddDateFilter={handleAddDateFilter}
      handleRemoveDateFilter={handleRemoveDateFilter}
      handleChangeDateFilterType={handleChangeDateFilterType}
    />
  );
};

export default PricesFiltersContainer;
