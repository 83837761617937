/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { sub } from 'date-fns';
import { buildNumberReset } from 'utils/appDefinitions';
import ganymedeActionTypes from './Actions/ganymedeActionTypes';
import { dateReviver } from '../../utils/util';
import definitions from './Definitions/definitions';

const today = new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate()); // needed to have date set on 00:00
const localState = localStorage.getItem('ganymedeState');
// const initialState = localStorage.getItem('ganymedeState') ? JSON.parse(localStorage.getItem('ganymedeState'), dateReviver) : {
const loadedState = localState && JSON.parse(localState, dateReviver);
const firstState = {
  pages: {},
  pagesIsUpdating: false,
  screenshots: [],
  screenshotsListIsUpdating: false,
  totalNbOfScreenshots: 0,
  explorer: {
    filtersSelection: {
      snapshotDate: {
        from: sub(today, { days: 1 }),
        to: today,
      },
      additionalSnapshotDates: [],
      'Park Group': [],
      Park: [],
      'Page Type': [],
      Version: [],
    },
    controls: {
      toggleGridView: false,
    },
  },
  prices: {
    filtersSelection: {
      snapshotDate: sub(today, { days: 1 }),
      additionalSnapshotDates: [],
      'Park Group': [],
      Park: [],
      Product: [],
    },
  },
  buildNumber: process.env.REACT_APP_BUILD_NUMBER || 'local',
};

// "Repair" state loaded from local storage in case a property is missing
// if (loadedState) {
//   for (const categoryName of Object.keys(firstState)) {
//     for (const optionName of (Object.keys((firstState).map((el) => !!el))[categoryName])) {
//       if (!loadedState[categoryName]) {
//         loadedState[categoryName] = firstState[categoryName];
//       } else if (!loadedState[categoryName][optionName]) {
//         loadedState[categoryName][optionName] = firstState[categoryName][optionName];
//       }
//       for (const subOptionName of Object.keys(firstState[categoryName][optionName])) {
//         if (!loadedState[categoryName][optionName][subOptionName]) {
//           loadedState[categoryName][optionName][subOptionName] = firstState[categoryName][optionName][subOptionName];
//         }
//       }
//     }
//   }
// }

// use firstState if build number is below the threshold value
const initialState = loadedState && (loadedState?.buildNumber === 'local' || loadedState?.buildNumber >= buildNumberReset) ? loadedState : firstState;

// eslint-disable-next-line consistent-return
const ganymedeReducer = produce((draft = initialState, action) => {
  const getAdditionalDate = ({ date, type }) => sub(date, definitions.dateRangeTypes.find((el) => el.name === type).timeDiff);

  switch (action.type) {
    case ganymedeActionTypes.UPDATE_PAGE_LIST:
      draft.pages = {};
      action.pages.forEach((page) => { draft.pages[page.name] = page; });
      // update filters
      // draft.filters.Park.values = [...new Set(action.pages.map((page) => page.park))].sort();
      // draft.filters['Page Type'].values = [...new Set(action.pages.map((page) => page?.page))].sort();
      // update total nb of screenshots
      draft.totalNbOfScreenshots = action.pages.reduce((acc, current) => acc + (current.itemsCount || 0), 0);
      break;


    case ganymedeActionTypes.SET_UPDATING:
      draft.pagesIsUpdating = action.isUpdating;
      break;


    case ganymedeActionTypes.UPDATE_FILTERED_SCREENSHOTS:
      draft.screenshots = action.screenshots;
      break;


    case ganymedeActionTypes.SET_SCREENSHOTS_LIST_ISUPDATING:
      draft.screenshotsListIsUpdating = action.screenshotsListIsUpdating;
      break;


    case ganymedeActionTypes.UPDATE_PAGE_PARAMS:
      const { pageName, parameterValues } = action;
      if (!draft.pages[pageName]) draft.pages[pageName] = { name: pageName };

      for (const parameterValue of parameterValues) {
        const { parameter, value } = parameterValue;
        draft.pages[pageName][parameter] = value;
      }
      break;


    case ganymedeActionTypes.UPDATE_FILTER:
      if (action.filterName === 'snapshotDate') {
        draft[action.submodule].filtersSelection.snapshotDate = action.filterValues;
        // update addionnaldateFilters if they depend on the primary date filter (i.e. if they're anything other than Custom)
        draft[action.submodule].filtersSelection.additionalSnapshotDates.filter((el) => el.type !== 'Custom').forEach((additionalDateFilter) => {
          if (action.submodule === 'explorer') {
            additionalDateFilter.from = sub(action.filterValues.from, definitions.dateRangeTypes.find((el) => el.name === additionalDateFilter.type).timeDiff);
            additionalDateFilter.to = sub(action.filterValues.to, definitions.dateRangeTypes.find((el) => el.name === additionalDateFilter.type).timeDiff);
          } else if (action.submodule === 'prices') {
            additionalDateFilter.value = sub(action.filterValues, definitions.dateRangeTypes.find((el) => el.name === additionalDateFilter.type).timeDiff);
          } else {
            // do nothing
          }
        });
      } else if (action.filterName.slice(0, 22) === 'additionalSnapshotDate') {
        if (action.submodule === 'explorer') {
          draft[action.submodule].filtersSelection.additionalSnapshotDates[action.filterName.slice(22)] = {
            type: draft[action.submodule].filtersSelection.additionalSnapshotDates[action.filterName.slice(22)].type,
            ...action.filterValues,
          };
        } else if (action.submodule === 'prices') {
          draft[action.submodule].filtersSelection.additionalSnapshotDates[action.filterName.slice(22)] = {
            type: draft[action.submodule].filtersSelection.additionalSnapshotDates[action.filterName.slice(22)].type,
            value: action.filterValues,
          };
        } else {
        // do nothing
        }
      } else {
        draft[action.submodule].filtersSelection[action.filterName] = action.filterValues;
      }
      break;


    case ganymedeActionTypes.ADD_DATE_FILTER:
      // by default set the type to PY
      if (action.submodule === 'explorer') {
        draft.explorer.filtersSelection.additionalSnapshotDates.push({
          type: 'PY',
          from: getAdditionalDate({ date: draft.explorer.filtersSelection.snapshotDate.from, type: 'PY' }),
          to: getAdditionalDate({ date: draft.explorer.filtersSelection.snapshotDate.to, type: 'PY' }),
        });
      } else if (action.submodule === 'prices') {
        draft.prices.filtersSelection.additionalSnapshotDates.push({
          type: 'Custom',
          value: sub(draft.prices.filtersSelection.snapshotDate, { days: 1 }),
        });
      } else {
        // do nothing
      }
      break;


    case ganymedeActionTypes.REMOVE_DATE_FILTER:
      draft[action.submodule].filtersSelection.additionalSnapshotDates = [
        ...draft[action.submodule].filtersSelection.additionalSnapshotDates.slice(0, action.additionalDateFilterID),
        ...draft[action.submodule].filtersSelection.additionalSnapshotDates.slice(action.additionalDateFilterID + 1),
      ];
      break;


    case ganymedeActionTypes.CHANGE_DATE_FILTER_TYPE:
      draft[action.submodule].filtersSelection.additionalSnapshotDates[action.additionalDateFilterID].type = action.value;
      if (action.submodule === 'explorer') {
        draft.explorer.filtersSelection.additionalSnapshotDates[action.additionalDateFilterID].from = getAdditionalDate({ date: draft.explorer.filtersSelection.snapshotDate.from, type: action.value });
        draft.explorer.filtersSelection.additionalSnapshotDates[action.additionalDateFilterID].to = getAdditionalDate({ date: draft.explorer.filtersSelection.snapshotDate.to, type: action.value });
      } else if (action.submodule === 'prices') {
        draft.prices.filtersSelection.additionalSnapshotDates[action.additionalDateFilterID].value = getAdditionalDate({ date: draft.prices.filtersSelection.snapshotDate, type: action.value });
      } else {
        // do nothing
      }
      break;


    case ganymedeActionTypes.UPDATE_EXPLORER_CONTROL:
      draft.controls[action.controlName] = action.newValue;
      break;


    case ganymedeActionTypes.SET_FILTERS_FROM_LINK:
      Object.keys(action.filtersSelection).forEach((filterName) => {
        draft[action.submodule].filtersSelection[filterName] = [action.filtersSelection[filterName]];
      });
      break;


    default:
      return draft;
  }
  const locaStorageState = { ...draft };
  locaStorageState.screenshots = [];
  localStorage.setItem('ganymedeState', JSON.stringify(locaStorageState));
});

export default ganymedeReducer;
