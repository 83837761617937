const productsMap = {
  AIT: {
    SDT: ['Single-Day Ticket', 'Ticket Only', 'Ticket Only (ages 3+)'],
    'SDT+ADD': ['Ticket + All-Day Dining Deal', 'Ticket + All-Day Dining Deal (ages 3+)'],
  },
  APO: {
    SDT: ['Single-Day Ticket', 'Ticket Only', 'Ticket Only (ages 3+)'],
    'SDT+ADD': ['Ticket + All-Day Dining Deal', 'Ticket + All-Day Dining Deal (ages 3+)', 'Ticket + Meal', 'Ticket + Meal (ages 3+)'],
  },
  APT: {
    SDT: ['Single-Day Ticket', 'Ticket Only', 'Ticket Only (ages 3+)'],
    'SDT+ADD': ['Ticket + All Day Dining', 'Ticket + All Day Dining (ages 3+)'],
  },
  BGT: {
    SDT: ['Guest (ages 3+)', 'Single-Day Ticket', 'Ticket Only', 'Ticket Only (ages 3+)'],
    'Ultimate Ticket Bundle': [
      'Ultimate Ticket Bundle',
      'Black Friday Presale Ticket Bundle',
      'Black Friday Sale Ticket Bundle',
      'Cyber Monday Sale Ticket Bundle',
    ],
    'SDT+ADD': ['Ticket + All-Day Dining', 'Ticket + All-Day Dining (ages 3+)'],
    'HOS SDT': ['Single Night', 'Single-Night Ticket'],
  },
  BGW: {
    SDT: ['Single-Day Ticket', 'Ticket ONLY', 'Ticket ONLY (ages 3+)'],
    'SDT+ADD': ['Ticket + All-Day Dining', 'Ticket + All-Day Dining  (ages 3+)'],
  },
  DCO: {
    Swim: ['GUEST (AGES 6+)', 'Signature Dolphin Swim Package'],
    'Swim + 2 Parks': ['Signature Dolphin Swim Package + 2 Parks'],
    'Swim + 3 Parks': ['Signature Dolphin Swim Package + 3 Parks'],
    'Day Resort': ['Day Resort Package', 'Day Resort Package '],
    'Day Resort + 2 Parks': ['Day Resort + 2 Parks'],
    'Day Resort + 3 Parks': ['Day Resort + 3 Parks'],
    'FL Res - Swim': ['FL Res - Signature Dolphin Swim Package'],
    'FL Res - Swim + 2 Parks': ['FL Res - Signature Dolphin Swim Package + 2 Parks'],
    'FL Res - Swim + 3 Parks': ['FL Res - Signature Dolphin Swim Package + 3 Parks'],
    'FL Res - Day Resort': ['FL Res - Day Resort Package '],
    'FL Res - Day Resort + 2 Parks': ['FL Res - Day Resort Package + 2 Parks'],
    'FL Res - Day Resort + 3 Parks': ['FL Res - Day Resort Package + 3 Parks'],
  },
  SPC: {
    SDT: ['Ages 2+', 'Single-Day Ticket'],
    'SDT+ADD': ['Ticket only (Ages 2+)'],
  },
  SPL: {
    SDT: [
      'Single-Day Ticket',
      'Date Specific Tickets',
      'One Day Ticket',
      'One Day Ticket (ages 2+)',
      'One Day Ticket - Free 2nd Visit (ages 2+)',
      'One Day Ticket- FREE 2nd Visit (ages 2+)',
      'One-Day Ticket',
    ],
    MDT: ['Second Visit Free- Two Day Ticket (ages 2+)', 'Two Day Ticket', 'Two Day Ticket (ages 2+)'],
  },
  SWC: {
    SDT: ['Guest', 'One Day Ticket', 'Single-Day Ticket', 'Ticket Only', 'Ticket Only (Ages 3+)'],
    'SDT+ADD': ['Ticket + All-Day Dining', 'Ticket + All-Day Dining Bundle', 'Ticket + All-Day Dining Bundle (Ages 3+)'],
    'HOS SDT': ['Single-Night Ticket'],
    MDT: ['2nd Visit Free - Two Day Ticket', 'Two Day Ticket - 2nd Visit Free'],
    'Ultimate Ticket Bundle': ['Black Friday Bundle', 'Bundle', 'Ultimate Bundle'],
  },
  SWO: {
    SDT: ['Guest', 'Guest (ages 3+)', 'Single-Day Ticket', 'Ticket Only', 'Ticket Only (ages 3+)'],
    'SDT+ADD': ['Ticket  + FREE All-Day Dining', 'Ticket + All-Day Dining', 'Ticket + All-Day Dining Deal (ages 3+)'],
    'HOS SDT': ['HOS SDT', 'Single-Night Ticket'],
    'Ultimate Ticket Bundle': ['Black Friday Presale Ticket Bundle', 'Black Friday Sale Ticket Bundle', 'Ultimate Ticket Bundle'],
  },
  SWT: {
    SDT: ['Single-Day Ticket', 'Ticket Only', 'Ticket Only (ages 3+)'],
    'SDT + 3 item Seven Seas Sampler': ['Ticket + 3 item Seven Seas Sampler  (ages 3+)'],
    'SDT + 7 item Seven Seas Sampler': ['Ticket + 7 item Seven Seas Sampler'],
    'SDT+ADD': ['Ticket + All-Day Dining', 'Ticket + All-Day Dining (ages 3+)', 'Ticket + All-Day Dining- (ages 3+)'],
  },
  WCW: {
    SDT: ['Single-Day Ticket', 'Ticket Only', 'Ticket Only (ages 3+)'],
    'SDT+ADD': ['Ticket + All-Day Dining', 'Ticket + All-Day Dining (ages 3+)'],
  },
};

module.exports = { productsMap };
