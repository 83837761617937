export default {
  UPDATE_PAGE_LIST: 'UPDATE_PAGE_LIST',
  UPDATE_FILTERED_SCREENSHOTS: 'UPDATE_FILTERED_SCREENSHOTS',
  SET_UPDATING: 'SET_UPDATING',
  UPDATE_PAGE_PARAMS: 'UPDATE_PAGE_PARAMS',
  UPDATE_FILTER: 'UPDATE_FILTER',
  ADD_DATE_FILTER: 'ADD_DATE_FILTER',
  REMOVE_DATE_FILTER: 'REMOVE_DATE_FILTER',
  CHANGE_DATE_FILTER_TYPE: 'CHANGE_DATE_FILTER_TYPE',
  SET_FILTERS_FROM_LINK: 'SET_FILTERS_FROM_LINK',
  UPDATE_EXPLORER_CONTROL: 'UPDATE_EXPLORER_CONTROL',
  SET_SCREENSHOTS_LIST_ISUPDATING: 'SET_SCREENSHOTS_LIST_ISUPDATING',
};
