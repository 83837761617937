/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { DateInput } from '@blueprintjs/datetime';
import { Button, HTMLSelect, Intent } from '@blueprintjs/core';
import definitions from 'AppModules/Ganymede/Definitions/definitions';
import MyMultiSelect from 'Components/MyMultiSelect/MyMultiSelect';
import './PricesFilters.css';

const PricesFilters = (props) => (
  <div className="PricesFilters">
    <div className="dateFilters">
      <h4>Snapshot Date</h4>
      <section className="primaryDateFilter">
        <DateInput
          formatDate={(date) => date.toLocaleDateString()}
          onChange={(date) => props.handleUpdateFilter('snapshotDate', date)}
          value={props.filtersSelection.snapshotDate}
          parseDate={(str) => new Date(str)}
          placeholder="MM/DD/YY"
          shortcuts
          // closeOnSelection={false}
          // startInputProps={{ fill: true }}
          // endInputProps={{ fill: true }}
        />
        <Button
          icon="add"
          intent={Intent.PRIMARY}
          onClick={props.handleAddDateFilter}
          minimal
          disabled={props.filtersSelection.additionalSnapshotDates?.length > 9}
        />
      </section>
      {props.filtersSelection.additionalSnapshotDates?.map((additionalDateFilter, additionalDateFilterID) => (
        // eslint-disable-next-line react/no-array-index-key
        <section className="additionalDateFilter" key={additionalDateFilterID}>
          <DateInput
            formatDate={(date) => date.toLocaleDateString()}
            onChange={(date) => props.handleUpdateFilter(`additionalSnapshotDate${additionalDateFilterID}`, date)}
            value={additionalDateFilter.value}
            parseDate={(str) => new Date(str)}
            placeholder="MM/DD/YY"
            shortcuts
            // closeOnSelection={false}
            // startInputProps={{ fill: true }}
            // endInputProps={{ fill: true }}
            disabled={additionalDateFilter.type !== 'Custom'}
          />
          <HTMLSelect
            options={definitions.dateRangeTypes.map((el) => el.name)}
            value={additionalDateFilter.type}
            onChange={(e) => props.handleChangeDateFilterType(additionalDateFilterID, e.target.value)}
            minimal
          />
          <Button icon="trash" intent={Intent.DANGER} onClick={() => props.handleRemoveDateFilter(additionalDateFilterID)} minimal />
        </section>
      ))}
    </div>
    <section key="Park Group">
      <h4>Park Group</h4>
      <MyMultiSelect
        items={props.filters['Park Group']?.values}
        selectedItems={props.filtersSelection['Park Group']}
        updateSelectedItems={(newItems) => props.handleUpdateFilter('Park Group', newItems)}
        ctrlSelectMode
      />
    </section>
    <section key="Park">
      <h4>Park</h4>
      <MyMultiSelect
        items={props.filters.Park?.values}
        selectedItems={props.filtersSelection.Park}
        updateSelectedItems={(newItems) => props.handleUpdateFilter('Park', newItems)}
        ctrlSelectMode
      />
    </section>
    <section key="Product">
      <h4>Product</h4>
      <MyMultiSelect
        items={props.relevantProducts}
        selectedItems={props.filtersSelection.Product}
        updateSelectedItems={(newItems) => props.handleUpdateFilter('Product', newItems)}
        ctrlSelectMode
      />
    </section>
  </div>
);

PricesFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  relevantProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  filtersSelection: PropTypes.shape({
    snapshotDate: PropTypes.object.isRequired,
    additionalSnapshotDates: PropTypes.arrayOf(PropTypes.object).isRequired,
    'Park Group': PropTypes.arrayOf(PropTypes.string).isRequired,
    Park: PropTypes.arrayOf(PropTypes.string).isRequired,
    Product: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  handleUpdateFilter: PropTypes.func.isRequired,
  handleAddDateFilter: PropTypes.func.isRequired,
  handleChangeDateFilterType: PropTypes.func.isRequired,
  handleRemoveDateFilter: PropTypes.func.isRequired,
};

export default PricesFilters;
