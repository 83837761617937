const pageTypes = ['Full Page', 'SDT Prices'];

const requiredFields = {
  'Full Page': ['name', 'active', 'URL', 'type', 'parkGroup', 'park', 'page', 'localIP', 'nonLocalIP'],
  'SDT Prices': ['name', 'active', 'URL', 'type', 'parkGroup', 'park', 'product', 'page', 'localIP', 'nonLocalIP'],
};

const dimSortings = {
  Park: ['SWO', 'APO', 'DCO', 'BGT', 'AIT', 'SWC', 'SPC', 'APC', 'SWT', 'APT', 'BGW', 'WCW', 'SPL'],
  Pages: [
    'Homepage',
    'Tickets',
    'SDT Prices',
    'Swim Prices', // DCO
    'Day Resort Prices', // DCO
    'Fun Cards',
    'Annual Pass',
    'Season Pass',
    'Limited Time Offers',
    'HOS Homepage',
    'HOS Tickets',
    'HOS SDT Prices',
    'Florida Residents', // DCO
    'Florida Residents SDT Prices', // DCO
    'Florida Residents Day Resort Prices', // DCO
    'Limited Time Offers', // DCO
    'Limited Time Offers SDT Prices', // DCO
    'Limited Time Offers Day Resort Prices', // DCO
    'Black Friday Offers', // DCO
    'Black Friday SDT Prices', // DCO
    'Black Friday Day Resort Prices', // DCO
  ],
  // for Prices section
  Products: [
    'SDT',
    'SDT+ADD',
    'HOS SDT',
    'Ultimate Ticket Bundle',
    'MDT',
    'Swim',
    'Swim + 2 Parks',
    'Swim + 3 Parks',
    'Day Resort',
    'Day Resort + 2 Parks',
    'Day Resort + 3 Parks',
    'FL Res - Swim',
    'FL Res - Swim + 2 Parks',
    'FL Res - Swim + 3 Parks',
    'FL Res - Day Resort',
    'FL Res - Day Resort + 2 Parks',
    'FL Res - Day Resort + 3 Parks',
  ],
};

const imgZoomOutFactor = 0.9;

const MAX_ITEMS_LIST = 500;
const MAX_ITEMS_LIST_COMPARE = 10;
const MAX_ITEMS_GRID = 50;

const explorerTableHeaders = [
  {
    title: 'Park',
    width: 20,
    alignHeader: 'center',
    alignItems: 'center',
  },
  {
    title: 'Page Type',
    width: 20,
    alignHeader: 'center',
    alignItems: 'center',
  },
  {
    title: 'Date',
    width: 15,
    alignHeader: 'center',
    alignItems: 'center',
  },
  {
    title: 'Time',
    width: 15,
    alignHeader: 'center',
    alignItems: 'center',
  },
  {
    title: 'Device',
    width: 10,
    alignHeader: 'center',
    alignItems: 'center',
  },
  {
    title: 'Version',
    width: 10,
    alignHeader: 'center',
    alignItems: 'center',
  },
  {
    title: 'Link',
    width: 5,
    alignHeader: 'center',
    alignItems: 'center',
  },
  {
    title: 'Status',
    width: 15,
    alignHeader: 'center',
    alignItems: 'center',
  },
];

const lineColors = [
  {
    dark: '#3b93d3',
    light: '#1f76b4',
  },
  {
    dark: '#F29D49',
    light: '#BF7326',
  },
  {
    dark: '#F55656',
    light: '#C23030',
  },
  {
    dark: '#9179F2',
    light: '#634DBF',
  },
  {
    dark: '#43BF4D',
    light: '#238C2C',
  },
  {
    dark: '#BFCCD6',
    light: '#8A9BA8',
  },
];

const filters = {
  Date: {
    type: 'date',
    minAllowed: new Date(2019, 8, 25),
    paramName: 'date',
  },
  'Park Group': {
    type: 'list',
    values: ['SeaWorld', 'Six Flags'],
    default: 'SeaWorld',
    paramName: 'parkGroup',
  },
  Park: {
    type: 'list',
    values: ['SWO', 'APO', 'DCO', 'BGT', 'AIT', 'SWC', 'SPC', 'SWT', 'APT', 'BGW', 'WCW', 'SPL'],
    default: 'SWO',
    paramName: 'park',
  },
  Page: {
    type: 'list',
    values: ['Homepage', 'Tickets', 'Fun Cards', 'Annual Pass', 'Limited Time Offers'],
    default: 'Homepage',
    paramName: 'page',
  },
  Version: {
    type: 'list',
    values: ['Local', 'Non-local'],
    default: 'Local',
    paramName: 'version',
  },
  Device: {
    type: 'list',
    values: ['Desktop', 'Mobile'],
    default: 'Desktop',
    paramName: 'device',
  },
};

export {
  pageTypes,
  requiredFields,
  dimSortings,
  imgZoomOutFactor,
  MAX_ITEMS_LIST,
  MAX_ITEMS_LIST_COMPARE,
  MAX_ITEMS_GRID,
  explorerTableHeaders,
  lineColors,
  filters,
};
