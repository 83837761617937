/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, SpinnerSize } from '@blueprintjs/core';
import MyBreadCrumbs from 'Components/MyBreadCrumbs/MyBreadCrumbs';
import PricesFiltersContainer from './PricesFilters/PricesFiltersContainer';
import PricesChartContainer from './PricesChart/PricesChartContainer';

const Prices = (props) => (
  <div className="Prices">
    <MyBreadCrumbs
      items={[
        { href: '/', text: 'Home' },
        { href: '/Ganymede', text: 'Time Machine' },
        { href: '/Ganymede/Prices', text: 'Prices' },
      ]}
    />
    <div className="PageContainer">
      <h2 style={{ float: 'left' }}>Ganyemede - Prices</h2>
      {props.isUpdating && (
        <div style={{ float: 'left', margin: '5px 5px 5px 15px' }}>
          <Spinner size={SpinnerSize.SMALL} />
        </div>
      )}
      <PricesFiltersContainer />
      {props.isUpdating ? <p>Loading...</p> : <PricesChartContainer title="SDT price" data={props.pricesData} width={window.innerWidth - 120} height={500} />}
    </div>
  </div>
);

Prices.propTypes = {
  isUpdating: PropTypes.bool,
  pricesData: PropTypes.any.isRequired,
};

Prices.defaultProps = {
  isUpdating: false,
};

export default Prices;
